@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html {
    font-family: 'Poppins', sans-serif;
  }

  @font-face {
    font-family: "Popins", sans-serif;
    src: url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");
  }
}

*{
  box-shadow : none !important;
  outline: none !important;

}


@media screen and (max-width: 1200px){
  .projectflow-hidden{
    /* display: none !important; */

    


  }
  .map-iframe{
    display: none !important;
  }
 .header-top {
  display: none !important;
 }
 .service--side{
  flex-direction:column !important;
 }
  
}

@media (max-width: 1200px){
  .sidebar-menu{
    display: none !important;
  }

}

.bg-hovring:hover{
  background-color: transparent !important ;
  color: black !important ;

}


.bg-white1:hover{
  background-color: transparent !important ;
  color: white !important ;
  
}



#fullstack1{
  /* transform: translate(42px, 18px); */
}


/*  target="_blank"  rel="noreferrer" */

ul a.active{
  -webkit-transform: scale(1.2);
  border-bottom: 1px solid white !important;
}

.navbar--container li{
  font-weight: 400;
}


.dropdown-content {
  display: none;
  position: absolute;
  left: -8px;
  background-color: #f1f1f1;
  color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #ddd;}
.dropdown-content a:hover {color: black;}

.dropdown:hover .dropdown-content {display: block;}



@media (max-width: 990px){
  .contact-btn{
    width: 7rem !important;
    text-align: center !important;
  }
  .know-btn{
    width: 5rem !important;
    text-align: center !important;
  }
}




/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px black; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: black; 
  border-radius: 10px;
  opacity: 0.6;
  transition: 0.1s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(35, 34, 34); 
  opacity: 1;
  
}


/* image Overlay */


.image {
	position: relative;

}

.image__img {
	display: block;
	width: 100%;
}

.image__overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	color: #ffffff;
	opacity: 0;
	transition: opacity 0.25s;
  border-radius: 8px;
}

.image__overlay--blur {
	backdrop-filter: blur(5px);
}

.image__overlay--primary {
	background: #000;
  
}

.image__overlay>* {
	transform: translateY(20px);
	transition: transform 0.25s;
}

.image__overlay:hover {
	opacity: 0.8;

}

.image__overlay:hover>* {
	transform: translateY(0);
}

.image__title {
  margin: 5%;
	font-size: 1.5em;
	font-weight: bold;
}

.image__description {
	font-size: 1em;
  display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  margin-top: 30%;
  margin-bottom: 30%;
}

.image__title1 {
  
  margin: 5%  ;
	font-size: 1em;
}


@media screen and (min-width: 500px)  {

  .width--class--all{

    width: 251px !important;
    
    }

}



/**/

.whole--hover{
background-color: white;
}

.whole--hover:hover{
background-color: black;
color: white;
}


.shadow--div{
  box-shadow: 0px 3px 14px #35313129 !important;
  }


  .height--flex {
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }






  